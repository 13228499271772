export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const APP_ENV = PROD_ENV;
export const API_LOG = APP_ENV === DEV_ENV;
export const API_TIMEOUT = 29000;
export const AUTH_HOST = "https://weblogin.asu.edu/cas";
export const ASU_AUTH_DOMAIN = ".aiml.asu.edu";
export const SIDEBAR_PLACEMENT_RIGHT = true;
export const MESSAGE_TYPE_JSON = true;

// DATE FORMATS
export const DATE_FORMAT1 = "MMM DD, YYYY";
export const DATE_FORMAT2 = "DD, MMM YY";
export const DATE_FORMAT3 = "YYYY-MM-DD";
export const DATE_FORMAT4 = "MM-DD-YYYY";
export const DATE_FORMAT5 = "MM/DD/YYYY";
export const TIME_FORMAT1 = "hh:mma";
export const TIME_FORMAT2 = "hh:mm";
export const TIME_FORMAT3 = "hh";
export const DATE_TIME = "Do MM YYYY, hh:mm a";

export const MESSAGE_TIMEOUT = 30; // seconds
export const THREE_MINUTE_TIMEOUT_MODELS = ["o1", "o1-mini"];
export const FORTY_FIVE_SECOND_TIMEOUT_MODELS = [
  "geminipro",
  "geminipro1_5",
  "geminiflash1_5",
];
export const MESSAGE_TIMEOUT_ERROR =
  "Hmm I'm having some trouble right now, try again in a little bit";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// UPLOAD VALIDATION
export const MAX_FILE_SIZE = 20; // MB

// VALIDATION REGEX
export const ALLOW_ALPHABETS_REGEX = new RegExp(/^[a-zA-Z\s]*$/); // allow only alphabets and white spaces
export const ALLOW_ALPHANUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/); // allow only alphabets and numbers

// ACCESS TYPES
export const ACCESS_TYPES = {
  AUTH: "auth",
  PRIVATE: "private",
  PUBLIC: "public",
};

// TOAST MESSAGES
export const TOAST_MESSAGES = {
  FILE_UPLOAD_SUCCESS: "File uploaded successfully",
  FILE_DELETE_SUCCESS: "File deleted successfully",
  CHAT_DELETE_SUCCESS: "Chat deleted successfully",
};

// REACT TOASTIFY ALERT CONFIGURATION
export const ALERT_POSITIONS = {
  TOP_Right: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};
export const ALERT_THEMES = {
  DARK: "dark",
  COLORED: "colored",
  LIGHT: "light",
};
export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  DEFAULT: "default",
};

// PUBLIC ROUTES
export const ERROR_ROUTE = "/404";
export const ACCESS_DENIED_ROUTE = "/access-denied";
// AUTH ROUTES

// PRIVATE ROUTES
export const CHAT_ROUTE = "/";
export const PROJECT_CHAT_ROUTE = "/:projectslug";

// CHAT OPTIONS
export const CHAT_OPTIONS = [
  { label: "chat", value: "chat" },
  { label: "speech", value: "speech" },
  { label: "email", value: "email" },
];

// PROMPT MODE OPTION
export const PROMPT_MODE_OPTIONS = [
  {
    title: "Combine LLM Knowledge and Your Uploaded Documents",
    value: "unrestricted",
    description:
      "The system will use both the model's knowledge and data from your uploaded documents.",
  },
  // {
  //   title: "Use Only LLM'S Knowledge",
  //   value: "both",
  //   description:
  //     "The system will rely solely on the pre-existing knowledge of the language model. No additional document or data you upload will be considered.",
  // },
  {
    title: "Only your uploaded documents",
    value: "restricted",
    description:
      "The system will only use data from your uploaded documents and exclude the model's existing knowledge.",
  },
];

// MODEL OPTIONS
export const MODEL_OPTIONS = [
  {
    label: "GPT 4o",
    value: "gpt4o",
    provider: "openai",
    imageSupport: true,
  },
  {
    label: "GPT 4o Mini",
    value: "gpt4o_mini",
    provider: "openai",
    leafs: 1,
    imageSupport: true,
  },
  {
    label: "O1",
    value: "o1",
    provider: "openai",
  },
  {
    label: "O1 mini",
    value: "o1-mini",
    provider: "openai",
    leafs: 1,
  },
  {
    label: "Nova Micro",
    value: "nova-micro",
    provider: "aws",
    leafs: 2,
  },
  {
    label: "Nova Lite",
    value: "nova-lite",
    provider: "aws",
    imageSupport: true,
    leafs: 1,
  },
  {
    label: "Nova Pro",
    value: "nova-pro",
    provider: "aws",
    imageSupport: true,
  },
  {
    label: "Gemini Pro",
    value: "geminipro",
    provider: "gcp-deepmind",
  },
  {
    label: "Gemini Pro 1.5",
    value: "geminipro1_5",
    provider: "gcp-deepmind",
    imageSupport: true,
  },
  {
    label: "Gemini Flash 1.5",
    value: "geminiflash1_5",
    provider: "gcp-deepmind",
    leafs: 1,
    imageSupport: true,
    audioSupport: true,
  },
  {
    label: "Claude 3 Opus",
    value: "claude3_opus",
    provider: "aws",
    imageSupport: true,
  },
  {
    label: "Claude 3 Sonnet",
    value: "claude3_sonnet",
    provider: "aws",
    imageSupport: true,
  },
  {
    label: "Claude 3.5 Sonnet",
    value: "claude3_5_sonnet",
    provider: "aws",
    imageSupport: true,
  },
  {
    label: "Claude 3 Haiku",
    value: "claude3_haiku",
    provider: "aws",
    imageSupport: true,
    leafs: 1,
  },
  {
    label: "Llama3 8b",
    value: "llama3-8b",
    provider: "aws",
    leafs: 2,
  },
  {
    label: "Llama3 70b",
    value: "llama3-70b",
    provider: "aws",
    leafs: 1,
  },
  {
    label: "Llama3 405b",
    value: "llama3-405b",
    provider: "aws",
    leafs: 1,
  },
  {
    label: "Mistral 7b",
    value: "mistral-7b",
    provider: "aws",
    leafs: 2,
  },
  {
    label: "Mistral 8x7b",
    value: "mistral-8x7b",
    provider: "aws",
    leafs: 1,
  },
  {
    label: "Mistral Large",
    value: "mistral-large",
    provider: "aws",
    leafs: 1,
  },
  {
    label: "Claude 2.1",
    value: "claude2_1",
    provider: "aws",
  },
  {
    label: "Claude 2",
    value: "claude2",
    provider: "aws",
    leafs: 1,
  },
  {
    label: "Claude Instant",
    value: "claudeinstant",
    provider: "aws",
    leafs: 1,
  },
  // {
  //   label: "Command",
  //   value: "command",
  //   provider: "aws",
  // },
  // {
  //   label: "Command Light",
  //   value: "commandlight",
  //   provider: "aws",
  // },
  {
    label: "GPT 3.5",
    value: "gpt3_5",
    provider: "openai",
  },
  {
    label: "GPT 3.5 (16k)",
    value: "gpt3_5-16k",
    provider: "openai",
  },
  {
    label: "GPT 4",
    value: "gpt4",
    provider: "openai",
  },
  {
    label: "GPT 4 (32k)",
    value: "gpt4-32k",
    provider: "openai",
  },
  {
    label: "GPT 4 Turbo",
    value: "gpt4turbo",
    provider: "openai",
    imageSupport: true,
    leafs: 1,
  },
];

export const SPEECH_ACCESS_PROJECTS = [
  "658b38bc546d4a0482c0e85ca913f3cc",
  "83962cee98de4f4cb83959aaaf0293e6",
  "f1df1178c8a04ae1895c1991c5f01b64",
  "6561c1a3bba043818b69e074d07f27ed",
];

