import _ from "lodash";
import {
  formatBytes,
  generateGuid,
  getFormattedUnixDateTime,
  toastAlert,
} from "../utils";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ALERT_TYPES } from "../constants";

export const manipulateMediaData = (data) => {
  if (_.isEmpty(data)) return {};
  const isSearchable = data?.search_status === "searchable";
  const isFailed =
    data?.search_status === "not_searchable" || data?.search_status === "error";

  const media = {
    id: data?.id ?? generateGuid(),
    name: data?.file_name ?? "",
    type: data?.file_type ?? "Document",
    size: formatBytes(data?.file_size),
    uploadDate: getFormattedUnixDateTime(data?.last_modified_unixtime),
    token: data?.token ?? generateGuid(),
    isSearchable,
    isFailed,
    isPending: !isSearchable && !isFailed,
    statusIcon: isSearchable ? faCheck : isFailed ? faTimes : faSpinner,
    statusColor: isSearchable ? "#22bb33" : isFailed ? "#bb2124" : "#f0ad4e",
    status: isSearchable ? "Searchable" : isFailed ? "Failed" : "Processing",
  };

  return media;
};

export const manipulateMediaListing = (data) => {
  let media = [];
  data.forEach((element) => {
    media.push(manipulateMediaData(element));
  });

  return media;
};
export const manipulateGenerateUrlFileData = (name, { url, fields }) => {
  let file = {
    name,
    url,
    key: fields.key ?? "",
    AWSAccessKeyId: fields.AWSAccessKeyId ?? "",
    "x-amz-security-token": fields["x-amz-security-token"] ?? "",
    policy: fields.policy ?? "",
    signature: fields.signature ?? "",
  };
  return file;
};
export const manipulateGenerateUrlData = (
  requesteddata,
  { files },
  isProjectApp
) => {
  const urls = [];
  files.forEach((file) => {
    const filename = isProjectApp ? file?.file_name : file;
    if (typeof requesteddata?.[filename] === "string") {
      toastAlert(`${filename} type is invalid`, ALERT_TYPES.ERROR);
    } else {
      urls.push(
        manipulateGenerateUrlFileData(filename, requesteddata?.[filename])
      );
    }
  });
  return urls;
};

export const manipulateModelsData = (data) => {
  return data?.models
    ?.filter((model) => model.is_active && model.model_status?.asugpt)
    .map(({ display, name, provider, capabilities }) => ({
      label: display?.name,
      value: name,
      imageSupport: capabilities?.image,
      visionSupport: capabilities?.vision,
      audionSupport: capabilities?.audio,
      speechSupport: capabilities?.speech,
      querySupport: capabilities?.query,
      leafs: display?.sustainability_rating || 0,
      provider,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
