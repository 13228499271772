import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Tooltip } from "antd";
import { Button } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faMicrophone,
  faPaperclip,
  faSquare,
  faStop,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faFile, faFileAudio } from "@fortawesome/free-regular-svg-icons";
import { promptFilesValidation, toastAlert } from "../../../../../utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import clsx from "clsx";
import { ALERT_TYPES, SPEECH_ACCESS_PROJECTS } from "../../../../../constants";
import { BounceLoader } from "react-spinners";
import { Icons } from "../../../../../theme";
import "./styles.scss";
// import SelectVoiceModal from "../select-voice";

const InputBox = React.forwardRef(
  (
    {
      projectId,
      sendMessage,
      isLoading,
      isProjectApp,
      projectData,
      appConfig,
      stopResponseGenerating,
      removeSelectedFile,
      selectFile,
      fileLoading,
      selectedModel,
      selectedFile,
      audioBoxPreviewHandler,
    },
    ref
  ) => {
    // STATES
    const [message, setMessage] = useState("");
    // const [selectVoicePreview, setselectVoicePreview] = useState(true);
    const [fileLoadingState, setFileLoadingState] = useState({});

    // CONST VALS
    const [form] = Form.useForm();
    const uploadFileInput = useRef(null);
    const isFilesLoading = Object.entries(fileLoadingState).length !== 0;
    const audioBoxPreview = !isProjectApp || projectData?.enable_voice;
    const speechPreview =
      SPEECH_ACCESS_PROJECTS.includes(projectId) || !isProjectApp;
    const inputPlaceHolder = appConfig?.inputBoxPlaceholder;
    const discalimerMessage = appConfig?.desclaimerMessage;
    const selectedImages = selectedFile.filter((file) => file.type === "image");
    const selectedAudio = selectedFile.filter((file) => file.type === "audio");
    const imageSupportWarning =
      selectedImages.length > 0 && !selectedModel.imageSupport;
    const audioSupportWarning =
      selectedAudio.length > 0 && !selectedModel.audioSupport;
    const fileSupportWarning = imageSupportWarning || audioSupportWarning;
    const {
      transcript,
      listening,
      resetTranscript,
      browserSupportsSpeechRecognition,
      browserSupportsContinuousListening,
    } = useSpeechRecognition();

    // HANDLERS
    // const handleUpload = (file, index) => {
    //   const totalSize = file.size;
    //   let uploadedSize = 0;

    //   const interval = setInterval(() => {
    //     uploadedSize += totalSize / 10;
    //     const progress = Math.min((uploadedSize / totalSize) * 100, 100);
    //     setUploadProgress((prev) => ({ ...prev, [index]: progress }));

    //     if (progress === 100) {
    //       clearInterval(interval);
    //     }
    //   }, 1000);
    // };
    const uploadFileHandler = (e) => {
      const files = e.target.files;
      let uploadedFiles = [];
      if (!files) return;

      const filesArray = Array.from(files);
      const totalfiles = selectedFile.length + filesArray.length;

      if (totalfiles > 5)
        toastAlert("You can only upload 5 files at a time.", ALERT_TYPES.ERROR);

      for (const file of filesArray) {
        const allfiles = [...selectedFile, ...uploadedFiles];
        const isExist = allfiles.find((item) => item.name === file.name);
        const isImageExist = allfiles.some((x) => x.type.includes("image"));
        const isAudioExist = allfiles.some((x) => x.type.includes("audio"));
        if (isExist) {
          toastAlert(
            `${file.name} is already uploaded. Please upload a different file.`,
            ALERT_TYPES.ERROR
          );
        } else if (file.type.includes("audio") && isImageExist) {
          toastAlert(
            `You can't upload audio file with image file.`,
            ALERT_TYPES.ERROR
          );
        } else if (file.type.includes("image") && isAudioExist) {
          toastAlert(
            `You can't upload image file with audio file.`,
            ALERT_TYPES.ERROR
          );
        } else if (promptFilesValidation(file)) {
          uploadedFiles.push(file);
          if (uploadedFiles.length >= 5) break;
        }
      }
      if (uploadedFiles.length > 0) selectFile(uploadedFiles);
      if (uploadFileInput.current) uploadFileInput.current.value = "";
    };

    const recordingHandler = () => {
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
        return;
      }
      setMessage("");
      SpeechRecognition.startListening({
        continuous: browserSupportsContinuousListening,
      });
    };

    const setMessageHandler = (e) => {
      setMessage(e.target.value);
    };

    const generateMessageHandler = () => {
      if (isLoading) return stopResponseGenerating();
      if (message.trim() === "") return;
      if (isFilesLoading) return;

      setMessage("");
      sendMessage(message, selectedFile);
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        generateMessageHandler();
      }
    };

    // HOOKS
    const inputRef = useCallback(
      (node) => {
        if (!node) return;
        // set textarea height
        node.style.height = "31px";
        const nodeHeight = node.scrollHeight;
        node.style.height = nodeHeight + "px";

        // set chat wrapper height
        if (!ref || !ref.current) return;
        const contentSpacing = window.innerWidth > 768 ? 95 : 65;
        const screenHeight = window.innerWidth > 768 ? 130 : 115;
        const defaultHeight =
          selectedFile.length > 0
            ? screenHeight + contentSpacing
            : screenHeight;

        const textareaHeight =
          nodeHeight > 20 ? nodeHeight - 40 : nodeHeight - 20;
        const calcheight = textareaHeight + defaultHeight;
        ref.current.style.height = `calc(100% - ${calcheight}px)`;
      },
      [message, selectedFile]
    );

    useEffect(() => {
      if (transcript !== "") {
        setMessage(transcript);
      }
    }, [transcript]);

    useEffect(() => {
      const currentlySelectedFiles = new Set(
        selectedFile.map((file) => file.name)
      );

      setFileLoadingState((prevLoading) => {
        const newLoadingState = { ...prevLoading };
        Object.keys(prevLoading).forEach((fileName) => {
          if (!currentlySelectedFiles.has(fileName)) {
            delete newLoadingState[fileName];
          }
        });
        return newLoadingState;
      });
    }, [selectedFile]);

    useEffect(() => {
      setFileLoadingState(fileLoading);
    }, [fileLoading]);

    return (
      <div className="input-wrapper">
        <div className="inputbox-wrapper">
          <div className={clsx("input-box", audioBoxPreview && "reduced")}>
            <Form className="input-form" form={form}>
              {(!isProjectApp || projectData?.enable_upload) && (
                <label htmlFor="file-uploader" className="upload-button">
                  Upload File
                  <input
                    type="file"
                    multiple
                    ref={uploadFileInput}
                    // disabled={fileLoading}
                    id="file-uploader"
                    onChange={uploadFileHandler}
                  />
                  <Tooltip
                    overlayInnerStyle={{
                      minWidth: 360,
                      wordBreak: "break-word",
                    }}
                    title={
                      <>
                        {isProjectApp ? (
                          <>
                            Upload a document, image or audio file to use in
                            this chat session.
                            <br />
                            <br />
                            Supported formats: Documents (e.g., DOCX, PDF, etc),
                            Audio (MP3, WAV), Images (PNG, JPG, JPEG).
                          </>
                        ) : (
                          <>
                            Upload a document, image or audio file to use in
                            this chat session. If you'd like the document to
                            remain accessible across all chats, you can add it
                            to the Media Library.
                            <br />
                            <br />
                            Supported formats: Documents (e.g., DOCX, PDF, etc),
                            Audio (MP3, WAV), Images (PNG, JPG, JPEG).
                          </>
                        )}
                      </>
                    }
                    placement="topLeft"
                  >
                    <FontAwesomeIcon
                      className={clsx("upload-icon")}
                      icon={faPaperclip}
                    />
                  </Tooltip>
                </label>
              )}
              {/* PROMPT GENERATOR */}
              <textarea
                className="ant-input"
                ref={inputRef}
                placeholder={
                  isProjectApp
                    ? projectData?.inputBoxPlaceholder ?? inputPlaceHolder
                    : inputPlaceHolder
                }
                value={message}
                onChange={setMessageHandler}
                onKeyDown={handleKeyPress}
              />
              {speechPreview && (
                <Tooltip
                  title={
                    !browserSupportsSpeechRecognition
                      ? "Browser doesn't support speech recognition"
                      : listening
                      ? "Stop microphone"
                      : "Use microphone"
                  }
                >
                  <button
                    className="upload-button"
                    onClick={recordingHandler}
                    disabled={!browserSupportsSpeechRecognition}
                  >
                    <FontAwesomeIcon
                      className="upload-icon"
                      icon={listening ? faStop : faMicrophone}
                    />
                  </button>
                </Tooltip>
              )}
              {/* GENERATE BUTTON */}
              <Button
                type="submit"
                className="send-btn"
                invertedTheme
                boldText
                onClick={generateMessageHandler}
                disabled={
                  (message.trim() === "" || isFilesLoading) && !isLoading
                }
              >
                <FontAwesomeIcon icon={isLoading ? faSquare : faArrowUp} />
              </Button>
            </Form>
            <div className="images-box">
              <div className="selected-images">
                {selectedFile.map((item, index) => {
                  const isFileLoading = fileLoadingState[item.name];
                  const isImage = item.type === "image";
                  const isAudio = item.type === "audio";
                  return (
                    <div
                      className={clsx("media-box", isImage ? "image" : "file")}
                    >
                      {isFileLoading && (
                        <div className="media-progress">
                          <BounceLoader color="#fff" size={15} />
                        </div>
                      )}
                      <span
                        role="button"
                        tabIndex={index}
                        className="delete-btn"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") removeSelectedFile(item.name);
                        }}
                        onClick={() => removeSelectedFile(item.name)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                      {isImage && <img src={item.data} alt={item.name} />}
                      {!isImage && (
                        <>
                          <div className="thumb">
                            <FontAwesomeIcon
                              icon={isAudio ? faFileAudio : faFile}
                            />
                          </div>
                          <div className="detail">
                            <h4>{item.name}</h4>
                            <p>{isAudio ? "Audio" : "Document"}</p>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {fileSupportWarning && (
              <p className="desclaimer-message">
                The current model chosen does not support{" "}
                {audioSupportWarning ? "audio" : "images"} so this request will
                be processed using another model.
              </p>
            )}
          </div>
          {audioBoxPreview && (
            <Tooltip title="Use voice mode">
              <button className="voice-toggle" onClick={audioBoxPreviewHandler}>
                <img src={Icons.Voice} alt="" />
              </button>
            </Tooltip>
          )}
        </div>
        {/* PROMPT TAGLINE */}
        <div className={clsx("tagline", fileSupportWarning && "warning")}>
          {isProjectApp
            ? projectData?.desclaimerMessage ?? discalimerMessage
            : discalimerMessage}
        </div>
        {/* <SelectVoiceModal
          preview={selectVoicePreview}
          previewHandler={() => setselectVoicePreview(false)}
        /> */}
      </div>
    );
  }
);

export default InputBox;
